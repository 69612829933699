import type { Link } from '@dx-ui/osc-link';
import { publicRuntimeConfig } from '../../constants';
import type { TFunction } from 'i18next';

/**
 * Hardcoded links are intended to be temporary until live CMS links are pulled in.
 * _id fields are carried over from a baseline query and are solely used to ensure
 * the footer implementation has something unique for the
 * key fields in the li elements.
 */
export const FOOTER_LINKS = (
  t: TFunction<'footer'>,
  locale: string,
  handleClick: React.MouseEventHandler<HTMLAnchorElement> | undefined
): Link[] => [
  {
    isNewWindow: false,
    label: t('globalPrivacyStatement'),
    url: `${publicRuntimeConfig.OHW_BASE_URL}${locale}/p/global-privacy-statement/`,
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('careers'),
    url: 'https://jobs.hilton.com',
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('development'),
    url: 'https://www.hilton.com/en/corporate/development/',
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('media'),
    url: 'https://stories.hilton.com',
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('webAccessibility'),
    url: `${publicRuntimeConfig.OHW_BASE_URL}${locale}/p/accessibility/`,
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('siteUsageAgreement'),
    url: `${publicRuntimeConfig.OHW_BASE_URL}${locale}/p/site-usage-agreement/`,
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('modernSlaveryAndHumanTrafficking'),
    url: 'https://cr.hilton.com/wp-content/uploads/2021/06/Hilton-Slavery-and-Trafficking-Statement-2020.pdf',
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('hhDiscountTermsAndConditions'),
    url: `${publicRuntimeConfig.OHW_BASE_URL}${locale}/hilton-honors/honors-discount-terms/`,
    onClick: handleClick,
  },
  {
    isNewWindow: false,
    label: t('tourismMediator'),
    url: `${publicRuntimeConfig.OHW_BASE_URL}${locale}/p/tourism-and-travel/`,
    onClick: handleClick,
  },
  {
    isNewWindow: true,
    label: t('adChoices'),
    url: 'https://optout.aboutads.info/#!/',
    onClick: handleClick,
  },
  {
    isNewWindow: true,
    label: t('hiltonHotline'),
    url: ' https://secure.ethicspoint.com/domain/media/en/gui/23073/index.html',
    onClick: handleClick,
  },
];
