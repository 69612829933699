import type { FormInputBase } from '@dx-ui/osc-form';
import * as React from 'react';
import { get, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { useSuggestions } from '@dx-ui/framework-places-autocomplete';
import { FormError, FormLabel } from '@dx-ui/osc-form';
import { Spinner } from '@dx-ui/osc-spinner';
import { Combobox, ComboboxInput, ComboboxList, ComboboxPopover } from '@reach/combobox';
import cx from 'classnames';

import HotelsNearMeOption from './location-options/hotels-near-me-option';

import type {
  PredictionService,
  AutocompletePrediction,
  PredictionStatusTypes,
  PlacesAutocompleteSuggestion,
} from '@dx-ui/framework-places-autocomplete';
import type { ComboboxInputProps } from '@reach/combobox';
import PopularDestinationsOptions from './location-options/popular-destinations';
import RecentSearchesOptions from './location-options/recent-searches';
import {
  getRecentSearches,
  saveSelectedSuggestToSession,
} from './location-options/location-options-utils';
import PlacesAutoCompleteSuggestionOptions, {
  AutoCompleteSuggestionOptionsWrapper,
} from './autoComplete-suggestion-options';
import { useGeocodeCoordinateQuery } from './gql/queries';

type BaseLocationInputOptions = FormInputBase<
  Omit<React.ComponentProps<'input'>, 'value' | 'onSelect'>
> &
  ComboboxInputProps;

export interface LocationInput extends BaseLocationInputOptions {
  /** minimum length to trigger a search */
  autoCompleteLength?: number;
  /** Set the lat/long bias for this search.
   * PredicionService [coordinate](/?path=/docs/library-framework-places-autocomplete--page)
   */
  coordinate?: PredictionService['coordinate'];
  /** Flag to ensure hotels near `coordinates` provided are displayed */
  enableHotelsNearMe?: boolean;
  /** language value passed from router */
  language: string;
  /** Flag to ensure recent searches are displayed */
  enableRecentSearches?: boolean;
  /** Need to provide context */
  placeIdFieldName?: string;
  /** List of predictions that are returned from the service */
  predictions: AutocompletePrediction[] | null;
  /**  popular destinations options */
  popularDestinationOptions?: PlacesAutocompleteSuggestion[] | null;
  /** Whether this input field is required */
  required?: HTMLInputElement['required'];
  /** Provide the sessionId to use with this search.
   * PredicionService [sessionId](/?path=/docs/library-framework-places-autocomplete--page)
   */
  sessionId?: PredictionService['sessionId'];
  status: PredictionStatusTypes | null;
  checkAutocompleteStatus?: boolean;
}

const isHandheld = (userAgent: string): boolean => {
  return /mobile|tablet|ipad|android/i.test(userAgent);
};

export const LocationInput: React.FC<React.PropsWithChildren<LocationInput>> = ({
  coordinate,
  enableHotelsNearMe = false,
  enableRecentSearches = false,
  name,
  predictions,
  required,
  optional,
  loading,
  label,
  labelClassName,
  language,
  className,
  registerOptions,
  sessionId,
  autoCompleteLength = 2,
  placeIdFieldName = 'placeId',
  status,
  checkAutocompleteStatus = true,
  popularDestinationOptions,
  ...rest
}) => {
  const { t } = useTranslation('osc-location');
  const errorId = `input-error-location`;
  const {
    getFieldState,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const [showAriaLabel, setShowAriaLabel] = React.useState(false);
  const { suggestions } = useSuggestions({ predictions });
  const inputName = name;
  const inputValue = useWatch({ name });
  const fieldError = get(errors, name);
  const hasError = !!fieldError;
  const hasSearchTerm = !!inputValue && inputValue.length >= autoCompleteLength;
  const hasSuggestions = suggestions.length > 0;
  const autoCompleteSelectionRequired = status === 'SELECTION_REQUIRED' && checkAutocompleteStatus;

  const { data, isFetching: isGeoCodeFetching } = useGeocodeCoordinateQuery(
    { language, location: coordinate, sessionToken: sessionId },
    { enabled: !!coordinate && enableHotelsNearMe }
  );

  const geocodeData = data?.geocode;
  const enablePopularDestinations = !!popularDestinationOptions;
  const isPlacesAutocompleteSuggestons = !(
    enableHotelsNearMe ||
    enablePopularDestinations ||
    enableRecentSearches
  );
  const [showSuggestions, setShowSuggestions] = React.useState(!isPlacesAutocompleteSuggestons);
  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      const hideSuggestions = isPlacesAutocompleteSuggestons && !hasSearchTerm;
      setShowSuggestions(!hideSuggestions);
    }
    //When customer freeform types still check autocomplete results to see whether a valid placeId is found
    setValue(
      placeIdFieldName,
      suggestions.find((suggestion) => suggestion.description === e.currentTarget.value)?.placeId ||
        ''
    );
  };

  const onFocus = () => {
    if (isPlacesAutocompleteSuggestons && !hasSearchTerm) {
      setShowSuggestions(false);
    } else {
      const locationFieldState = getFieldState(name);
      const locationFieldError = !!locationFieldState?.error?.message;
      if (locationFieldError) setShowSuggestions(false);
    }
    setShowAriaLabel(true);
  };

  const showHotelsNearMe = !!(enableHotelsNearMe && !hasSearchTerm && geocodeData);
  const showPopularDestinations = enablePopularDestinations && !hasSearchTerm;
  const showRecentSearches = enableRecentSearches && !hasSearchTerm;
  const showAutoCompleteSuggestions = !(
    showHotelsNearMe ||
    showPopularDestinations ||
    showRecentSearches
  );
  const showSuggestionsPopover =
    showSuggestions || showHotelsNearMe || showPopularDestinations || showRecentSearches;
  const showSuggestionOptions =
    showSuggestions &&
    (hasSuggestions || showHotelsNearMe || showPopularDestinations || showRecentSearches);

  const onSelect = async (selectValue: string) => {
    setValue(inputName, selectValue);
    const selectedSuggestion = getSelectedSuggestion(selectValue);
    setValue(placeIdFieldName, selectedSuggestion?.placeId ?? '');
    setShowSuggestions(false);
    if (enableRecentSearches && selectedSuggestion) {
      saveSelectedSuggestToSession(selectedSuggestion);
    }
    await trigger(inputName);

    if (autoCompleteSelectionRequired) await trigger(inputName);
  };

  const getSelectedSuggestion = (selectValue: string) => {
    const recentSearches = getRecentSearches();
    if (showRecentSearches && recentSearches?.length) {
      return recentSearches.find((suggestion) => suggestion.description === selectValue);
    } else if (showPopularDestinations) {
      return popularDestinationOptions?.find(
        (suggestion) => suggestion?.description === selectValue
      );
    } else return suggestions?.find((suggestion) => suggestion.description === selectValue);
  };

  const getAriaText = () => {
    if (!hasSearchTerm || !showAriaLabel) {
      return '';
    }
    switch (true) {
      case loading:
        return t('loading');
      case autoCompleteSelectionRequired && hasSuggestions:
        return t('ariaSuggestionRequired', {
          drawBridgeNotification: t('drawBridgeSelect'),
          ariaSuggestions: t('ariaSuggestions', { count: suggestions.length }),
        });
      case hasSuggestions:
        return t('ariaSuggestions', { count: suggestions.length });
      case autoCompleteSelectionRequired && !hasSuggestions:
        return t('drawBridgeNoResults');
      default:
        return t('noSuggestions');
    }
  };

  const validatePredictions = (value: string) => {
    const isValidPrediction = suggestions.some((suggestion) => suggestion.description === value);
    return isValidPrediction || t('selectSuggestion');
  };

  // HACK: Temporarily switch ComboboxInput to uncontrolled while isComposing is true
  // to prevent selections from an Input Method Editor (IME) on mobile from being appended to
  // the text input instead of replaced.
  const isComposing = React.useRef(false);
  const isMobileIME =
    (language === 'ja' || /^zh.*/.test(language)) &&
    typeof navigator !== 'undefined' &&
    isHandheld(navigator.userAgent);
  const handleCompositionEnd = () => {
    isComposing.current = false;
  };
  const handleCompositionStart = () => {
    isComposing.current = true;
  };
  const openOnFocus = !isPlacesAutocompleteSuggestons && !hasError;
  return (
    <div className="w-full" data-osc-product="shop-form-location">
      <FormLabel
        label={label}
        required={required}
        optional={optional}
        hasError={hasError}
        className={cx('w-full brand-ey:font-normal brand-lx:font-semibold', labelClassName)}
      >
        <Combobox className="relative" onSelect={onSelect} openOnFocus={openOnFocus}>
          <ComboboxInput
            {...rest}
            className={cx(
              'form-input w-full brand-ey:border-primary-alt brand-lx:bg-bg-light brand-lx:border-primary brand-lx:placeholder:text-text brand-ou:bg-transparent brand-ou:border-primary',
              className,
              {
                'form-error': hasError,
              }
            )}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            aria-label={t('ariaInput', { label })}
            aria-invalid={hasError}
            aria-describedby={errorId}
            data-osc-product="shop-form-location-input"
            required={required}
            {...register(inputName, {
              ...registerOptions,
              required: required && !autoCompleteSelectionRequired ? t('validation') : false,
              validate:
                (autoCompleteSelectionRequired && validatePredictions) || registerOptions?.validate,
              onBlur: () => setShowAriaLabel(false),
            })}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            // https://jira.hilton.com/browse/WEBPLAT-570 to address warning “ComboboxInput is changing from uncontrolled to controlled.”
            value={isMobileIME && isComposing.current ? undefined : inputValue}
            onCompositionEnd={handleCompositionEnd}
            onCompositionStart={handleCompositionStart}
          />
          {/* Only Display the aria-label when user has focus on input */}
          <p className="sr-only" aria-live="assertive">
            {getAriaText()}
          </p>
          <ComboboxPopover
            portal={false}
            className={cx(
              'text-text bg-bg border-border absolute z-50 transform-gpu rounded border border-solid text-sm font-normal shadow-lg',
              { hidden: !showSuggestionsPopover }
            )}
          >
            {autoCompleteSelectionRequired ? (
              <div className="bg-warn-alt px-4 py-2">
                <AutoCompleteSuggestionOptionsWrapper iconType="alert">
                  <span className="text-base font-bold" aria-hidden>
                    {hasSuggestions ? t('drawBridgeSelect') : t('drawBridgeNoResults')}
                  </span>
                </AutoCompleteSuggestionOptionsWrapper>
              </div>
            ) : null}

            {showSuggestionOptions ? (
              <ComboboxList>
                {showHotelsNearMe && !isGeoCodeFetching ? (
                  <HotelsNearMeOption
                    geocodeData={geocodeData}
                    key={`hotels-nearby-${!!geocodeData}`}
                  />
                ) : null}
                {showPopularDestinations ? (
                  <PopularDestinationsOptions
                    isNearByEnabled={showHotelsNearMe}
                    popularDestinations={popularDestinationOptions}
                    showRecentDestinations={showRecentSearches}
                  />
                ) : null}
                {showRecentSearches ? (
                  <RecentSearchesOptions isNearByEnabled={showHotelsNearMe} />
                ) : null}
                {showAutoCompleteSuggestions ? (
                  <PlacesAutoCompleteSuggestionOptions suggestions={suggestions} />
                ) : null}
              </ComboboxList>
            ) : !hasSuggestions && !hasError ? (
              <div className="p-3" aria-hidden>
                {loading ? <Spinner size="md" delay={0} /> : t('noSuggestions')}
              </div>
            ) : null}
          </ComboboxPopover>
        </Combobox>
      </FormLabel>
      <FormError id={errorId} error={hasError && fieldError} className="lg:absolute" />
    </div>
  );
};

LocationInput.displayName = 'LocationInput';

export default LocationInput;
