import type { PlacesAutocompleteSuggestion } from '@dx-ui/framework-places-autocomplete';
import { isBrowser } from '@dx-ui/utilities-is-browser';
type IExpire<T> = {
  expireDate: number;
  instance: T;
};

/**
 * @param ttl milliseconds
 */
export const saveToSession = <T>(object: T, key: string, ttl: number) => {
  const env: IExpire<T> = {
    instance: object,
    expireDate: new Date().getTime() + ttl,
  };
  window.sessionStorage.setItem(key, JSON.stringify(env));
};

export const retrieveFromSession = <T>(key: string) => {
  if (!isBrowser) {
    return null;
  }

  const env: { expireDate: Date; instance: T } | null = window?.sessionStorage?.getItem(key)
    ? JSON.parse(window?.sessionStorage?.getItem(key) as string)
    : null;

  if (!env) return null;

  const ttl = new Date(env.expireDate).getTime() - new Date().getTime();

  if (ttl <= 0) {
    window?.sessionStorage?.removeItem(key);
    return null;
  }
  return env.instance;
};

export const RECENT_SEARCH = {
  SESSION_KEY: 'RecentSearches',
  SELECTED_SUGGESTION_SESSION_KEY: 'SelectedSuggestion',
  REFRESH_TTL: 3600,
  REFRESH_TTL_2WEEK: 604800 * 2,
  DISPLAY_COUNT: 5,
};

const getLatestRecentSearches = ({
  suggestion,
  displayCount = RECENT_SEARCH.DISPLAY_COUNT,
}: { suggestion?: PlacesAutocompleteSuggestion; displayCount?: number } = {}) => {
  const selectedSuggestion = retrieveFromSession<PlacesAutocompleteSuggestion>(
    RECENT_SEARCH.SELECTED_SUGGESTION_SESSION_KEY
  );
  //get selected suggestion
  const newRecentSearchOption = suggestion ?? selectedSuggestion;
  let recentSearches;
  if (newRecentSearchOption) {
    recentSearches = getRecentSearches() ?? [];
    const recentSearchFound = recentSearches
      .map((recentSearch) => recentSearch.description)
      .includes(newRecentSearchOption.description);
    if (!recentSearchFound) {
      // adjust recent search list
      if (recentSearches.length === displayCount) recentSearches.pop();
      //add selected options to recent search list
      if (newRecentSearchOption) recentSearches.unshift({ ...newRecentSearchOption });
    }
    //reset selected suggestion
    saveToSession(
      null,
      RECENT_SEARCH.SELECTED_SUGGESTION_SESSION_KEY,
      RECENT_SEARCH.REFRESH_TTL * 1000
    );
  }

  return recentSearches;
};

export const saveRecentSearchesToSession = (
  suggestion?: PlacesAutocompleteSuggestion,
  displayCount?: number
) => {
  const recentSearches = getLatestRecentSearches({ suggestion, displayCount });
  if (recentSearches) {
    saveToSession(
      recentSearches,
      RECENT_SEARCH.SESSION_KEY,
      RECENT_SEARCH.REFRESH_TTL_2WEEK * 1000
    );
  }
};
export const saveSelectedSuggestToSession = (suggestion: PlacesAutocompleteSuggestion) => {
  if (suggestion) {
    saveToSession(
      suggestion,
      RECENT_SEARCH.SELECTED_SUGGESTION_SESSION_KEY,
      RECENT_SEARCH.REFRESH_TTL * 1000
    );
  }
};

export const getRecentSearches = () => {
  const recentSearchOptions = retrieveFromSession(
    RECENT_SEARCH.SESSION_KEY
  ) as PlacesAutocompleteSuggestion[];
  return recentSearchOptions;
};
