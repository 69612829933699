import type { FormProps } from '@dx-ui/osc-form';
import { Form, FormConnector } from '@dx-ui/osc-form';
import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import type { AdditionalQSParameters } from './shop-form.utils';
import { generateShopFormUrl, generateSanitizedDefaultValues } from './shop-form.utils';
import type { DEFAULT_FORM_VALUES } from './shop-form.constants';
import { ShopFormSummary } from './shop-form.summary';
import { useQueryClient } from '@tanstack/react-query';
import { serverSideGeocodeQuery } from './gql/queries';
import type { FormState } from 'react-hook-form';
import { useLocale } from '@dx-ui/utilities-dates';
import type { Hhonors, SpecialRates } from '@dx-ui/osc-special-rates';
import type { ShopFormRoom } from '@dx-ui/osc-rooms';
import type { ShopFormDatesProps } from './shop-form.dates';
import { saveRecentSearchesToSession } from '@dx-ui/osc-location';
import { Speedbump } from '@dx-ui/osc-speedbump';
import { useShopFormSpeedBump } from './hooks/use-shop-form-speedbump';
import type { GeocodeQuery } from './gql/types';
import { GeocodeFailModal } from './shop-form.geocode-fail-modal';
import { serverSideFeatureConfigsQuery } from '@dx-ui/framework-i18n';
import { logDynatraceError } from '@dx-ui/framework-dynatrace';

type FormValues = Omit<
  typeof DEFAULT_FORM_VALUES,
  'dates' | 'hhonors' | 'numRooms' | 'numAttendees' | 'specialRates' | 'numNights'
> & {
  dates: {
    arrivalDate: Date | null;
    departureDate: Date | null;
    datesFlex: boolean;
  };
  hhonors: Hhonors | null;
  numRooms: number | null;
  numNights: number | null;
  numAttendees: number | null;
  specialRates: SpecialRates;
};

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface FormDataValues extends FormValues {
  dates: ShopFormDatesProps;
  hhonors: Hhonors;
  rooms: ShopFormRoom[];
}

export interface FormDefaultValues extends DeepPartial<FormDataValues> {
  dates?: Partial<ShopFormDatesProps>;
  rooms?: ShopFormRoom[];
  specialRates?: Partial<SpecialRates>;
}

export type TargetOHWPage = 'search' | 'ten-plus-search' | 'book' | 'ten-plus-book' | 'search-zero';

type ShopForm = {
  /** Additional query string parameters that are typically pass-through parameters that are not used in widget directly */
  additionalQSParameters?: AdditionalQSParameters;
  buttonClassName?: string;
  /** override for the cta button text */
  cta?: string;
  /** initial values to set in form */
  defaultValues?: FormDefaultValues;
  excludeProvider?: boolean;
  isLoading?: boolean;
  /** language value passed from router */
  language: string;
  onClose?: () => void;
  onSubmit?: (args0: { formData: FormDataValues; url: string | null; locale: string }) => void;
  shouldDirtyToSubmit?: boolean;
  /** Adds screen reader text to update button **/
  submitOpensNewTab?: boolean;
  /** Target OHW page to generate URL for from onSubmit. If none passed url in onSubmit will return null */
  summaryOptions?: {
    expanded?: boolean;
    initiallyExpanded?: boolean;
    state?: FormDataValues;
    onEditClick?: () => void;
    showChildAges?: boolean;
    summaryClassName?: string;
    summaryWrapperClassName?: string;
    suppressHeader?: boolean;
    hideRoomSummary?: boolean;
    type: 'search' | 'stay';
  };
  /** Target OHW page to generate URL for from onSubmit. If none passed url in onSubmit will return null */
  targetOHWPage?: TargetOHWPage;
  /** optional title of the form */
  title?: string;
  wrapperClassName?: string;
  useFormProps?: Pick<FormProps, 'useFormProps'> & {
    defaultValues?: FormDefaultValues;
  };
  /**Opt-in to route flex date searches to locations page results - <br/>
   * Once routed to dream flow, dates and flexible date selection will be wiped <br/>
   * If user selects again in dream flow, will be routed to search flow flex dates experience.
   */
  enableDatesFlexDreamRouting?: boolean;
  baseUrl?: string;
  canAlwaysSubmit?: boolean;
  onSubmitClick?: (formState: FormState<FormDataValues>) => void;
  renderAfterUpdateButton?: React.ReactNode;
  shouldDisplayGeocodeErrorModal?: boolean;
  /** Override for the Error Banner Defaults to displaying */
  hasErrorBanner?: boolean | undefined;
} & Omit<FormProps, 'onSubmit'>;

export const wrapperClassNameDefault = [
  'container', // container needs to be first for wrappers to slice(1) to remove
  'relative',
  'flex',
  'flex-wrap',
  'items-end',
  'justify-center',
  'gap-2',
  'lg:gap-3',
  'lg:flex-nowrap',
];

export const wrapperClassNameDefaultWithLocation = [...wrapperClassNameDefault, 'lg:mb-6'];

/**
 * The OSC shop form is an extended version of the OSC form component with some key additional features:
 *
 * `defaultValues` is somewhat strictly typed with `FormDefaultValues`. This allows consumers to pass in search-specific defaults like dates, room parameters, and special rates. The defaults also allow us to handle business logic cases—such as generating valid search URLs—more easily.

To use more freeform form values, consider using [the OSC `<Form />` component instead](https://nx-storybook-ui.dig-t.hhc.hilton.com/?path=/docs*library-components-form--combining-form-components).
 *
 * Use the `additionalQSParameters` prop to include any “passthrough” query strings. `displayCurrency` are used to ensure the URL returned from `onFormSubmit()` is generated properly.
 *
 * Certain error handling is built in and standardized. For example, if a user tries to select a freeform rate while trying to use points, the form displays messaging that lets the user know they cannot use both points and freeform rates.
 *
 * The `targetOHWPage` allows for auto generation of a specific OHW page URL as part of the `onSubmit` callback. The resulting URI is only valid if consumers pass the correct initial values into `additionalQSParameters` and `defaultValues` props.
 */
export const ShopForm: React.FC<React.PropsWithChildren<ShopForm>> = ({
  additionalQSParameters,
  buttonClassName,
  children,
  cta,
  defaultValues,
  excludeProvider,
  isLoading: isLoadingProp,
  language,
  onClose,
  onSubmit,
  shouldDirtyToSubmit = false,
  onSubmitClick,
  canAlwaysSubmit = false,
  submitOpensNewTab,
  summaryOptions,
  targetOHWPage,
  title,
  wrapperClassName,
  enableDatesFlexDreamRouting = false,
  renderAfterUpdateButton,
  shouldDisplayGeocodeErrorModal = false,
  hasErrorBanner,
  ...rest
}) => {
  const client = useQueryClient();
  const formContentRef = React.useRef<HTMLDivElement | null>(null);
  const editButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [
    {
      hasLocationInput,
      hasRoomsButton,
      hasSpecialRatesButton,
      hasNumRoomsButton,
      hasNumAttendeesButton,
    },
    setElementState,
  ] = React.useState<ReturnType<typeof getElementState>>(getElementState(formContentRef.current));
  const { t, ready } = useTranslation(['osc-link', 'osc-shop-form']);
  const isLoading = !ready || isLoadingProp;
  const [isExpanded, setIsExpanded] = React.useState(
    !!summaryOptions?.initiallyExpanded || !summaryOptions
  );
  const [isGeocodeError, setIsGeoCodeError] = React.useState(false);
  const expanded = !!summaryOptions?.expanded || isExpanded;
  const intlLocale = useLocale({ language });
  const locale = intlLocale?.language || 'en';
  const {
    isSpeedBumpVisible,
    handleSpeedBumpClose,
    handleSpeedBumpContinue,
    shouldShowSpeedBump,
    setSpeedBumpData,
  } = useShopFormSpeedBump({ locale, onSubmit, targetOHWPage });

  //React hook form doesn't recommend undefined defaultValues. So merge user values with defaults object to pass into consumer form values.
  const defaultValuesWithDefaults = React.useMemo(
    () => generateSanitizedDefaultValues(defaultValues),
    [defaultValues]
  );

  const [internalSummaryState, setInternalSummaryState] =
    React.useState<FormDataValues>(defaultValuesWithDefaults);
  const summaryState = summaryOptions?.state || internalSummaryState;

  const onFormSubmit = async (data: FormDataValues) => {
    const formData = {
      ...data,
      rooms: data.rooms.map((room) => ({
        adults: room.adults,
        children: room.children.map((c) => {
          return { age: Number.isNaN(c.age) ? null : c.age };
        }),
      })),
    };
    saveRecentSearchesToSession();
    setInternalSummaryState(formData);

    const hasGeoCodeInput = formData.query || formData.placeId;
    const hasFlexDates = formData.dates.datesFlex && enableDatesFlexDreamRouting;
    const shouldAttemptToGeocode =
      hasGeoCodeInput && (hasFlexDates || shouldDisplayGeocodeErrorModal);

    let geocodeData: GeocodeQuery = {};

    if (shouldAttemptToGeocode) {
      let isSupportedLanguage = false;
      try {
        const autocompleteTranslateConfig = await serverSideFeatureConfigsQuery(client, {
          names: ['config-ui-translate-autocomplete'],
        });
        const configLanguages = autocompleteTranslateConfig?.featureConfigs?.[0]?.config?.languages;
        isSupportedLanguage = !!configLanguages.length && configLanguages.includes(locale);
      } catch (error) {
        if (error instanceof Error || typeof error === 'string')
          logDynatraceError('OSC_SHOP_FORM', error, 'failed to fetch autoCompleteTranslateConfig');
      }
      try {
        geocodeData = await serverSideGeocodeQuery(client, {
          address: formData?.query,
          placeId: formData?.placeId,
          sessionToken: formData?.sessionToken,
          language: isSupportedLanguage ? locale : 'en',
        });

        if (!geocodeData?.geocode && shouldDisplayGeocodeErrorModal) return setIsGeoCodeError(true);
      } catch (error) {
        if (shouldDisplayGeocodeErrorModal) {
          return setIsGeoCodeError(true);
        }
        if (error instanceof Error || typeof error === 'string')
          logDynatraceError('OSC_SHOP_FORM', error, 'failed to fetch geocode data');
      }
    }

    if (onSubmit) {
      const url = targetOHWPage
        ? await generateShopFormUrl({
            additionalQSParameters,
            formData,
            locale,
            targetOHWPage,
            enableDatesFlexDreamRouting,
            geocodeData,
            client,
          })
        : null;

      if (shouldShowSpeedBump(url)) {
        setSpeedBumpData({ formData, url });
        return;
      }
      onSubmit({ formData, url, locale });
    }
  };

  React.useEffect(() => {
    if (!isLoading && expanded) {
      setElementState(getElementState(formContentRef.current));
    }
  }, [expanded, isLoading]);

  function closeShopForm() {
    setIsExpanded(false);
    onClose?.();
    editButtonRef?.current?.focus();
  }
  const formContent = (
    <FormConnector<FormDataValues>>
      {({ formState }) => {
        const { isValid, isDirty } = formState;
        const canSubmit =
          canAlwaysSubmit || (summaryOptions || shouldDirtyToSubmit ? isValid && isDirty : isValid);
        return (
          <div>
            <GeocodeFailModal
              isGeocodeError={isGeocodeError}
              setIsGeoCodeError={setIsGeoCodeError}
            />
            {summaryOptions ? (
              <div
                className={cx(summaryOptions.summaryWrapperClassName, {
                  'border-border border-b pb-2': expanded,
                })}
              >
                <ShopFormSummary
                  className={summaryOptions.summaryClassName || 'container'}
                  arrivalDate={summaryState.dates.arrivalDate}
                  departureDate={summaryState.dates.departureDate}
                  isLoading={isLoading}
                  language={language}
                  location={summaryState.query}
                  onEditClick={() => {
                    summaryOptions?.onEditClick?.();
                    if (summaryOptions?.expanded === undefined) {
                      setIsExpanded(true);
                    }
                  }}
                  rooms={summaryState.rooms}
                  showChildAges={summaryOptions.showChildAges}
                  summaryType={summaryOptions.type}
                  hideRoomSummary={summaryOptions.hideRoomSummary}
                  isExpanded={expanded}
                  editButtonRef={editButtonRef}
                />
              </div>
            ) : null}
            {!isLoading && expanded ? (
              <div className={cx({ 'pt-2': summaryOptions })}>
                {!!title && <h2 className="mb-4 text-2xl font-bold lg:text-4xl">{title}</h2>}
                <div
                  ref={formContentRef}
                  className={
                    wrapperClassName ||
                    cx(...wrapperClassNameDefault, {
                      'md:flex-nowrap':
                        !hasRoomsButton &&
                        !hasSpecialRatesButton &&
                        !hasNumRoomsButton &&
                        !hasNumAttendeesButton,
                    })
                  }
                >
                  {summaryOptions && !summaryOptions.suppressHeader && !hasLocationInput ? (
                    <header
                      className="w-full font-bold lg:mb-2.5 lg:w-auto"
                      id="shop-form-header"
                      data-testid="shop-form-header"
                      tabIndex={-1}
                    >
                      {summaryOptions.type === 'search'
                        ? t('osc-shop-form:editSearchTitleCase')
                        : t('osc-shop-form:editStayTitleCase')}
                    </header>
                  ) : null}
                  {children}
                  <div className="flex w-full max-w-sm md:w-auto md:flex-1">
                    <button
                      data-testid="search-submit-button"
                      disabled={!canSubmit}
                      {...(onSubmitClick && { onClick: () => onSubmitClick(formState) })}
                      className={
                        buttonClassName || 'shop-form-btn-submit w-full md:w-auto md:flex-1'
                      }
                      type="submit"
                    >
                      {cta || t('osc-shop-form:ctaButton')}
                      {submitOpensNewTab ? (
                        <span className="sr-only">{t('osc-link:newTab')}</span>
                      ) : null}
                    </button>
                  </div>
                  {renderAfterUpdateButton}
                  {summaryOptions ? (
                    <button
                      className="absolute -top-2.5 order-last lg:static lg:-ml-2 ltr:right-1 md:ltr:right-4 rtl:-left-1 md:rtl:-left-4"
                      type="button"
                      onClick={closeShopForm}
                    >
                      <CloseIcon className="fill-primary size-10" />
                      <span className="sr-only">{t('osc-shop-form:close')}</span>
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}
            {isSpeedBumpVisible ? (
              <Speedbump
                isShowing={true}
                onClose={handleSpeedBumpClose}
                onContinue={handleSpeedBumpContinue}
              />
            ) : null}
          </div>
        );
      }}
    </FormConnector>
  );

  if (excludeProvider) {
    return (
      <FormConnector<FormDataValues>>
        {({ handleSubmit }) => (
          <form {...rest} noValidate onSubmit={handleSubmit(onFormSubmit)}>
            {formContent}
          </form>
        )}
      </FormConnector>
    );
  }

  return (
    <Form
      onSubmit={onFormSubmit}
      useFormProps={{ defaultValues: defaultValuesWithDefaults }}
      {...rest}
      hasErrorBanner={hasErrorBanner}
    >
      {formContent}
    </Form>
  );
};

function getElementState(formContentElement: HTMLDivElement | null) {
  return {
    hasLocationInput: formContentElement?.querySelector('[data-reach-combobox]'),
    hasRoomsButton: formContentElement?.querySelector('[data-osc-product="search-rooms-button"]'),
    hasSpecialRatesButton: formContentElement?.querySelector(
      '[data-osc-product="search-rates-button"]'
    ),
    hasNumRoomsButton: formContentElement?.querySelector('#shop-form-numRooms'),
    hasNumAttendeesButton: formContentElement?.querySelector('#shop-form-numAttendees'),
  };
}

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m17.35 6.65c-.2-.2-.51-.2-.71 0l-4.65 4.65-4.64-4.65c-.2-.2-.51-.2-.71 0s-.2.51 0 .71l4.65 4.65-4.65 4.65c-.2.2-.2.51 0 .71.1.1.23.15.35.15s.26-.05.35-.15l4.65-4.65 4.65 4.65c.1.1.23.15.35.15s.26-.05.35-.15c.2-.2.2-.51 0-.71l-4.65-4.65 4.65-4.65c.2-.2.2-.51 0-.71z" />
    </svg>
  );
}

export default ShopForm;
