import Head from 'next/head';
import { publicRuntimeConfig } from '../../constants';
import { useWrappedRouter } from '../../hooks';
import type { GeocodePlaceType } from '../../gql/operations';
import { useFeatureConfigsQuery } from '../../gql/queries';
import { getSearchLanguagesFromRuleUiTranslateConfig } from './meta-data-utils';

export type IMetaDataProps = {
  brandCode: string | undefined;
  seoSchema?: string;
  pageTitle: string;
  path: string;
  description: string;
  configRules?: string[];
  isGlobalBounds?: boolean;
  locationPageMatchType?: GeocodePlaceType;
};

export const MetaData = ({
  brandCode,
  seoSchema,
  pageTitle,
  path,
  description,
  configRules,
  isGlobalBounds,
  locationPageMatchType,
}: IMetaDataProps) => {
  const { data: featureConfigData } = useFeatureConfigsQuery({
    names: ['rule-ui-translate-search'],
  });
  const ruleUiTranslateSearchData = featureConfigData?.featureConfigs?.find(
    (config) => config.name === 'rule-ui-translate-search'
  );
  const searchLanguages = getSearchLanguagesFromRuleUiTranslateConfig(
    ruleUiTranslateSearchData?.conditions
  );
  const { router } = useWrappedRouter();
  const baseUrl = publicRuntimeConfig.OHW_BASE_URL;
  const faviconSrc = `${publicRuntimeConfig.HILTON_ASSETS_URI}/modules/assets/img/brand/${brandCode}/favicon_${brandCode}.ico`;
  /**
   * Using the larger apple-touch-icon for the og:Image since open graph
   * does not support SVG's. Previously in DX-Categories no images were used.
   */
  const ogImageSrc = `${publicRuntimeConfig.HILTON_ASSETS_URI}/modules/assets/img/WW/open-graph-image-1137x422.png`;
  const cleanPath = path.replace(/[?#].*$/, ''); // canonical url should not include query or hash values
  const canonicalHref = `${baseUrl}${router.locale}${cleanPath}${
    cleanPath[cleanPath.length - 1] !== '/' ? '/' : ''
  }`;
  const isLocationsPage = path.includes('/locations');

  const getLocationCategory = () => {
    if (cleanPath === '/locations') return 'default';
    if (isGlobalBounds) return 'global';
    return locationPageMatchType;
  };

  return (
    <Head>
      {!!seoSchema && (
        <script
          key="seo-schema"
          dangerouslySetInnerHTML={{ __html: seoSchema }}
          type="application/ld+json"
        />
      )}
      <link rel="canonical" href={canonicalHref} />
      <title>{pageTitle}</title>
      <meta content={description} name="description" />
      <meta content="" name="keywords" />
      <meta name="og:title" content={pageTitle} />
      <meta name="og:site_name" content="Hilton" />
      <meta name="og:type" content="website" />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={ogImageSrc} />
      <meta name="og:url" content={canonicalHref} />
      <meta content={configRules?.join('/') || ''} name="dx-page-url-suffix" />
      {/* dynatrace logging for page categories only on locations for now */}
      {isLocationsPage ? (
        <>
          <meta content={getLocationCategory()} name="locations-category" />
          <meta content={cleanPath} name="locations-path" />
        </>
      ) : null}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content="hilton.com" property="twitter:domain" />
      <meta content={canonicalHref} property="twitter:url" />
      <meta content={pageTitle} name="twitter:title" />
      <meta content={description} name="twitter:description" />
      <meta content={ogImageSrc} name="twitter:image" />
      <link href={faviconSrc} rel="icon" type="image/x-icon" />
      <link data-testid="favicon" href={faviconSrc} rel="shortcut icon" type="image/x-icon" />
      <link
        href={`${baseUrl}en${cleanPath}${cleanPath[cleanPath.length - 1] !== '/' ? '/' : ''}`}
        hrefLang="x-default"
        rel="alternate"
      />
      {searchLanguages?.map((locale) => {
        const href = `${baseUrl}${locale}${cleanPath}${
          cleanPath[cleanPath.length - 1] !== '/' ? '/' : ''
        }`;
        return <link href={href} hrefLang={locale} key={locale} rel="alternate" />;
      })}
      ;
    </Head>
  );
};
