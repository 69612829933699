import { useMediaQuery } from 'usehooks-ts';
import { MOBILE_FRIENDLY_ENHANCEMENTS } from '../constants';
import { useGetMVTSelections } from './use-get-mvt-selections';

export const useMobileShopFormFilterMvt = () => {
  const isOver321UnderTablet = useMediaQuery('(min-width: 321px) and (max-width: 768px)');

  const { isMVTVariant } = useGetMVTSelections({
    agentId: MOBILE_FRIENDLY_ENHANCEMENTS.agentId,
    MVTVariants: ['b', 'c', 'd'],
    isProvisional: isOver321UnderTablet,
  });
  return {
    showShopFormSummary: isMVTVariant && (isMVTVariant.b || isMVTVariant.d) && isOver321UnderTablet,
    shouldHorizontalStroll:
      isMVTVariant && (isMVTVariant.c || isMVTVariant.d) && isOver321UnderTablet,
    isOver321UnderTablet,
  };
};
