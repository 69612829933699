import type { FormInputBase } from '@dx-ui/osc-form';
import type { ComboboxInputProps } from '@reach/combobox';
import type * as React from 'react';
import { getRecentSearches } from './location-options-utils';
import type { PlacesAutocompleteSuggestion } from '@dx-ui/framework-places-autocomplete';
import PlacesAutoCompleteSuggestionOptions from '../autoComplete-suggestion-options';
import { useTranslation } from 'next-i18next';

type LocationPopularDestinationsOption = ComboboxInputProps &
  FormInputBase<Omit<React.HTMLAttributes<HTMLInputElement>, 'value' | 'onSelect'>>;

const LocationPopularDestinationsOption = ({
  isNearByEnabled,
  popularDestinations,
  popularDestinationsCount = 5,
  showRecentDestinations = false,
}: {
  isNearByEnabled?: boolean;
  popularDestinations?: PlacesAutocompleteSuggestion[];
  popularDestinationsCount?: number;
  showRecentDestinations?: boolean;
}) => {
  const { t } = useTranslation('osc-location');
  const displayCount = isNearByEnabled ? 3 : popularDestinationsCount;

  const popularDestinationsOptions = popularDestinations?.slice(
    0,
    displayCount
  ) as unknown as PlacesAutocompleteSuggestion[];
  const recentSearchOptions = showRecentDestinations ? getRecentSearches() : [];
  const showPopularDestinations = !(!!showRecentDestinations && recentSearchOptions?.length);

  return showPopularDestinations ? (
    <PlacesAutoCompleteSuggestionOptions
      header={t('optionsHeader.popularDestinations')}
      suggestions={popularDestinationsOptions}
    />
  ) : null;
};

export { LocationPopularDestinationsOption };
export default LocationPopularDestinationsOption;
